import { MPCNotificationService } from "./PCF/notification-service";
import { ProductComparisonModel, Product, NavigationTab } from "../../Models/Product.generated";
import { ErrorMessage } from "../../Models/ErrorMessage.generated";
import { NlVanillajax, INlVanillajaxParams } from "./helpers/vanillajax";
import { MPCComparePageButtonUrlUpdater } from "./helpers/comparePageButtonUrlUpdater";
import { MPCAddElementContext } from "./typings/addElementContext";
import { MPCErrorHandlerModule } from "./errorhandler";
import { MpcSettingsModule } from "./helpers/MpcSettings";

export module MPCLocalStorageService {
    import AddElementContext = MPCAddElementContext.AddElementContext;
    import NotificationService = MPCNotificationService.NotificationService;
    import ErrorHandlerManager = MPCErrorHandlerModule.ErrorHandlerManager;
    import ComparePageButtonUrlUpdater = MPCComparePageButtonUrlUpdater.ComparePageButtonUrlUpdater;
    import MpcSettings = MpcSettingsModule.MpcSettings;

    export enum AddResult {
        Added,
        Replaced,
        RejectedCapacity,
        RejectedDuplicate
    }

    export class ProductComparisonModelManager {
        private static _instance: ProductComparisonModelManager;
        private notificationService: NotificationService;
        private localStorageAbstraction: LocalStorageAbstraction;
        private localStorageCache: ProductComparisonModel;
        private runningServiceCall: Promise<ProductComparisonModel>;
        private comparePageButtonUrlUpdater: ComparePageButtonUrlUpdater;
        private readonly DuplicateNotFound: number = -1;
        private readonly DuplicateExact: number = -2;

        static get instance() {
            return this._instance || (this._instance = new this());
        }

        constructor() {
            this.init();
        }

        private init(): void {
            this.notificationService = NotificationService.instance;
            this.localStorageAbstraction = LocalStorageAbstraction.instance;
            this.localStorageCache = this.localStorageAbstraction.readLocalStorage();
            this.comparePageButtonUrlUpdater = ComparePageButtonUrlUpdater.instance;
            // here we load the model (from localStorage and with server-sync if deemed necessary) 
            // for later usage by all the components
            this.getModel();
        }

        public getModel(): Promise<ProductComparisonModel> {
            if (this.runningServiceCall) {
                // this service call is a singleton, avoid useless parallel calls 
                return this.runningServiceCall;
            }
            this.runningServiceCall = new Promise((resolve, reject) => {
                try {
                    this.localStorageCache = this.localStorageAbstraction.readLocalStorage();
                    if (this.localStorageCache && this.localStorageCache.products.length > 0 
                        && !this.localStorageCache.activeNavigationTab) {
                        this.localStorageCache.activeNavigationTab = this.localStorageCache.products[0].categoryName;
                    }
                    if (this.localStorageCache && typeof this.localStorageCache.mobileEnabledCategories != "object") {
                        this.localStorageCache.mobileEnabledCategories = [];
                    }
                    this.validateLocalStorage();
                } catch (err) {
                    ErrorHandlerManager.instance.LogError(<ErrorMessage>{
                        message: "resetting mpc localStorage because: " +
                            err +
                            ", localStorage: " +
                            JSON.stringify(this.localStorageCache)
                    }); 
                    this.localStorageCache = undefined;
                }

                const isNewlyLoggedOut = !MpcSettings.instance.isLoggedIn && this.localStorageCache && !!this.localStorageCache.isLoggedIn;
                if (!this.localStorageCache || ProductComparisonModelManager.isTimedOut(this.localStorageCache) 
                    || isNewlyLoggedOut) {
                    this.createNewLocalStorage();
                    resolve(this.localStorageCache);
                    return;
                }
                if (MpcSettings.instance.isLoggedIn && !this.localStorageCache.isLoggedIn) {
                    this.localStorageCache.isLoggedInNewly = true;
                }
                this.localStorageCache.isLoggedIn = MpcSettings.instance.isLoggedIn;
                const needsValidation = this.needsValidation();
                if (!needsValidation) {
                    if (this.localStorageCache.pageLanguage !== MpcSettings.instance.culture) { // if for logical readability :)
                        this.localStorageCache.pageLanguage = MpcSettings.instance.culture;
                    }
                    
                    this.onUpdatedModel(true);
                    
                    resolve(this.localStorageCache);
                    return;
                }

                this.sync()
                    .then((model)=>resolve(model))
                    .catch((error)=>reject(error));
            });
            this.runningServiceCall.then((model) => {
                this.runningServiceCall = null;
            })
            return this.runningServiceCall;
        }

        private sync(): Promise<ProductComparisonModel> {
            const printPageContent = this.localStorageCache.printPageContent;
            this.localStorageCache.printPageContent = null;

            let params: INlVanillajaxParams = <INlVanillajaxParams>{};
            params.url = MpcSettingsModule.MpcSettings.instance.apiUrl +
                'Products/Sync';
            params.data = JSON.stringify(this.localStorageCache);

            const countBefore = this.localStorageCache.products.length;

            return new NlVanillajax(params).postWithPromise<ProductComparisonModel>()
                .then((model) => {
                    if (!model || !model.products) {
                        this.localStorageCache.printPageContent = printPageContent;
                        return Promise.reject("service returned no useful result");
                        //throw new Error("service returned no useful result");
                    }
                    // false will not be send over the wire, but we don't want undefined values
                    model.clearStoredEmptyNavigationTabs = !!model.clearStoredEmptyNavigationTabs;
                    model.hasClientSideChange = false;
                    this.localStorageCache = model;
                    this.localStorageCache.printPageContent = printPageContent;
                    this.onUpdatedModel(true); //skipSaving=true, because 'validate' saves internally
                    const countAfter = model.products.length;
                    if (countBefore === 0 && countAfter > 0) {
                        this.setMobileHeaderMarkerOn();
                    } else if (countBefore > 0 && countAfter === 0) {
                        this.setMobileHeaderMarkerOff();
                    }
                    return this.localStorageCache;
                })
                .catch((err) => {
                    this.localStorageCache.printPageContent = printPageContent;
                    return Promise.reject(err);
                });
        }

        private validateLocalStorage(): void {
            //there were some problems with old/invalid localStorage contents, so we detect and reset them
            if (this.localStorageCache) {
                if (!Array.isArray(this.localStorageCache.products))
                    throw "localStorage is corrupted - products is no array";
                if (!this.localStorageCache.lastUpdateTime)
                    throw "localStorage is corrupted - lastUpdateTime is not set";
                if (typeof this.localStorageCache.lastUpdateTime !== "number")
                    throw "localStorage is corrupted - lastUpdateTime is no number";
                if (this.localStorageCache.products.length > 0) {
                    if (!this.localStorageCache.activeNavigationTab)
                        throw "localStorage is corrupted - activeNavigationTab is not set";
                    if (typeof this.localStorageCache.activeNavigationTab !== "string")
                        throw "localStorage is corrupted - activeNavigationTab is no string";
                }
                if (!this.localStorageCache.pageLanguage)
                    throw "localStorage is corrupted - pageLanguage is not set";
                if (typeof this.localStorageCache.pageLanguage !== "string")
                    throw "localStorage is corrupted - pageLanguage is no string";
                if (typeof this.localStorageCache.clearStoredEmptyNavigationTabs !== "boolean")
                    throw "localStorage is corrupted - clearStoredEmptyNavigationTabs is no boolean";
                if (!Array.isArray(this.localStorageCache.emptyNavigationTabs))
                    throw "localStorage is corrupted - emptyNavigationTabs is no array";
                if (this.localStorageCache.products.length > 0) {
                    if (!this.localStorageCache.products[0].articleId.masterArticleNo) {
                        throw "localStorage is corrupted - products[0].articleId.masterArticleNo is not set";
                    }
                    if (!this.localStorageCache.products[0].articleId.salesArticleVariantKey) {
                        throw "localStorage is corrupted - products[0].articleId.salesArticleVariantKey is not set";
                    }
                    if (!this.localStorageCache.products[0].articleId.seoDescription) {
                        throw "localStorage is corrupted - products[0].articleId.seoDescription is not set";
                    }
                    if (!this.localStorageCache.products[0].articleId.seoSlug) {
                        throw "localStorage is corrupted - products[0].articleId.seoSlug is not set";
                    }
                    if (!this.localStorageCache.products[0].categoryName) {
                        throw "localStorage is corrupted - products[0].categoryName is not set";
                    }
                    if (!this.localStorageCache.products[0].categoryUrl) {
                        throw "localStorage is corrupted - products[0].categoryUrl is not set";
                    }
                    if (!this.localStorageCache.products[0].detailsPageUrl) {
                        throw "localStorage is corrupted - products[0].detailsPageUrl is not set";
                    }
                    if (!this.localStorageCache.products[0].mainImage) {
                        throw "localStorage is corrupted - products[0].mainImage is not set";
                    }
                    if (!this.localStorageCache.products[0].originNavigationKeyPath) {
                        throw "localStorage is corrupted - products[0].originNavigationKeyPath is not set";
                    }
                    if (typeof this.localStorageCache.products[0].navigationOrder !== "number") {
                        throw "localStorage is corrupted - products[0].mainImage is not set";
                    }
                    if (!this.localStorageCache.products[0].title) {
                        throw "localStorage is corrupted - products[0].mainImage is not set";
                    }
                }
            }
        }
        
        public setActiveNavigationTab(activeNavigationTab: string): void {
            if(!this.localStorageCache)
                this.getModel();
            if (this.localStorageCache.activeNavigationTab !== activeNavigationTab) {
                this.localStorageCache.activeNavigationTab = activeNavigationTab;
                this.onUpdatedModel(true); //don't save, who cares
            }
        }
        
        public activateOnMobileCategory(categoryNavKey: string): void {
            if(!this.localStorageCache)
                this.getModel();

            this.localStorageCache.mobileEnabledCategories.push(categoryNavKey);
            this.onUpdatedModel(true);//dont save, who cares
        }

        public isActivatedOnMobileCategory(categoryNavKey: string): boolean {
            if(!this.localStorageCache)
                this.getModel();

            return this.localStorageCache.mobileEnabledCategories &&
                   this.localStorageCache.mobileEnabledCategories.indexOf(categoryNavKey) >= 0;
        }

        public deactivateOnMobileCategory(categoryNavKey: string): void {
            if(!this.localStorageCache)
                this.getModel();

            const idx = this.localStorageCache.mobileEnabledCategories.indexOf(categoryNavKey);
            this.localStorageCache.mobileEnabledCategories.splice(idx, idx >= 0 ? 1 : 0);
            this.onUpdatedModel(true);//dont save, who cares
        }

        public addProduct(product: Product): AddResult {
            if (this.localStorageCache) {
                this.setActiveNavigationTab(product.categoryName);

                const duplicateCheckResult = this.checkDuplicateProductPerCategory(
                    product.articleId.salesArticleVariantKey,
                    product.categoryName,
                    product.articleId.masterArticleNo
                );
                if (duplicateCheckResult === this.DuplicateNotFound && this.isAlreadyAtLimit(product.categoryName)) {
                    this.notificationService.notifyMaximumProductsCountReached();
                    return AddResult.RejectedCapacity;
                }
                
                if (duplicateCheckResult === this.DuplicateNotFound) {
                    this.appendProductToList(product, false);
                    return AddResult.Added;
                }
                else {
                    if (duplicateCheckResult !== this.DuplicateExact) {
                        this.localStorageCache.products[duplicateCheckResult] = product;
                        this.localStorageCache.hasClientSideChange = true;
                        this.onUpdatedModel(false);
                    }

                    // just show new product notification, because product is already added:
                    this.notificationService.notifyNewProductAdded(product.title, product.mainImage, product.altTag);

                    if (duplicateCheckResult !== this.DuplicateExact) {
                        return AddResult.Replaced;
                    } else {
                        return AddResult.RejectedDuplicate;
                    }
                }
            }
            else {
                this.createNewLocalStorage();

                this.appendProductToList(product, false);

                return AddResult.Added;
            }
        }

        private onUpdatedModel(skipSaving: boolean): Promise<ProductComparisonModel> {
            return new Promise((resolve, reject) => {
                const afterSave = (model: ProductComparisonModel) => {
                    this.comparePageButtonUrlUpdater.onUpdatedModel(model);
                    this.localStorageAbstraction.writeLocalStorage(model);
                }

                if (!skipSaving && this.localStorageCache.isLoggedIn) {
                    this.sync()
                        .then((syncedModel) => {
                            afterSave(syncedModel);
                            resolve(syncedModel);
                        })
                        .catch((error) => {
                            reject(error);
                        });
                } else {
                    afterSave(this.localStorageCache);
                }
            });
        }

        public removeAllProductsOfCategory(categoryName: string): Promise<ProductComparisonModel> {
            return new Promise((resolve, reject) => {
                let articleRemoved: boolean = false;
                const productsAfter: Product[] = [];
                for (let i = 0; i < this.localStorageCache.products.length; i++) {
                    const tmpProduct: Product = this.localStorageCache.products[i];
                    if (tmpProduct.categoryName !== categoryName) {
                        productsAfter.push(tmpProduct);
                    } else {
                        articleRemoved = true;
                    }
                }
                if (productsAfter.length == 0) {
                    //unmarks when removing the last
                    this.setMobileHeaderMarkerOff();
                }

                this.localStorageCache.products = productsAfter;
                if (this.localStorageCache.products.length > 0) {
                    this.setActiveNavigationTab(this.localStorageCache.products[0].categoryName);
                } else {
                    this.setActiveNavigationTab(null);
                }

                if (articleRemoved) {
                    this.localStorageCache.hasClientSideChange = true;
                    this.onUpdatedModel(false);
                }
            });
        }
        
        private setMobileHeaderMarkerOn(): void {
            if (window.shell)
                window.shell.publishTo('ESMKT.MegaMenu.MarkItem', "mpc");
        }

        private setMobileHeaderMarkerOff(): void {
            if (window.shell)
                window.shell.publishTo('ESMKT.MegaMenu.UnmarkItem', "mpc");
        }

        public removeProduct(categoryName: string, salesArticleVariantKey: string): Product {
            let articleRemoved: Product = null;

            for (let i = 0; i < this.localStorageCache.products.length; i++) {
                const tmpProduct: Product = this.localStorageCache.products[i];

                if (tmpProduct.articleId.salesArticleVariantKey === salesArticleVariantKey
                    && tmpProduct.categoryName === categoryName) {
                    this.localStorageCache.products.splice(i, 1);
                    //unmarks when removing the last
                    if (this.localStorageCache.products.length === 0) {
                        this.setMobileHeaderMarkerOff();
                    }
                    articleRemoved = tmpProduct;

                    break;
                }
            }
            if (!this.localStorageCache.products.some(p => p.categoryName === categoryName)) {
                if (this.localStorageCache.products.length > 0) {
                    this.setActiveNavigationTab(this.localStorageCache.products[0].categoryName);
                } else {
                    this.setActiveNavigationTab(null);
                }
            }

            if (articleRemoved) {
                this.localStorageCache.hasClientSideChange = true;
                this.onUpdatedModel(false);
            }

            return articleRemoved;
        }
        
        public addProductBySavKey(salesArticleVariantKey: string, seoSlug: string, origin: string, originNavKeyPathOverride: string = undefined): Promise<Product> {
            const context: AddElementContext = new class implements AddElementContext {
                salesArticleVariantKey: string;
                originSeoSlug: string;
                origin: string;
            };

            context.salesArticleVariantKey = salesArticleVariantKey;
            context.originSeoSlug = seoSlug;
            context.origin = origin;

            // Call controller method, via ajax, and get image and text info from MPC
            let params: INlVanillajaxParams = <INlVanillajaxParams>{};
            params.url = MpcSettingsModule.MpcSettings.instance.apiUrl +"Products/GetProductInfo";
            params.data = JSON.stringify(context);

            let nlVanillajax = new NlVanillajax(params);
            let postWithPromise = nlVanillajax.postWithPromise<Product>();

            postWithPromise.then((productDataFromService: Product) => {
                    if (productDataFromService) {
                        if (originNavKeyPathOverride) {
                            productDataFromService.originNavigationKeyPath = originNavKeyPathOverride;
                        }
                        this.appendProductToList(productDataFromService, true);
                    }
                })
                .catch((err) => {
                    ErrorHandlerManager.instance.LogError(<ErrorMessage>{
                        message: "error getting product info because: " +
                            err +
                            ", salesArticleVariantKey: " +
                            salesArticleVariantKey
                    }); 
                });

            return postWithPromise;
        }

        private checkDuplicateProductPerCategory(salesArticleVariantKey: string, categoryName: string, masterArticleNo: number): number {
            for (let i = 0; i < this.localStorageCache.products.length; i++) {
                const product: Product = this.localStorageCache.products[i];

                // avoid duplicate products
                if (product.articleId.masterArticleNo === masterArticleNo
                    && product.categoryName === categoryName) {
                    if (product.articleId.salesArticleVariantKey === salesArticleVariantKey) {
                        return this.DuplicateExact;
                    } else {
                        return i; //same MaNo but different SavKey -> this product will be replaced by the new variant
                    }
                }
            }

            return this.DuplicateNotFound;
        }

        private isAlreadyAtLimit(categoryName: string): boolean {
            let productsAmount: number = 0;
            let result: boolean = false;

            for (let i = 0; i < this.localStorageCache.products.length; i++) {
                const product: Product = this.localStorageCache.products[i];

                // avoid more then 4 products per category
                if (product.categoryName === categoryName) {
                    productsAmount++;

                    if (productsAmount === MpcSettings.instance.maxProducts) {
                        result = true;
                        break;
                    }
                }
            }

            return result;
        }
        
        private createNewLocalStorage(): void {
            const localStorageModel = {} as ProductComparisonModel;
            localStorageModel.products = new Array<Product>();
            localStorageModel.emptyNavigationTabs = new Array<NavigationTab>();
            localStorageModel.clearStoredEmptyNavigationTabs = false;
            localStorageModel.activeNavigationTab = null;
            localStorageModel.pageLanguage = MpcSettings.instance.culture;
            localStorageModel.hasClientSideChange = false;
            localStorageModel.lastUpdateTime = new Date().getTime(); // we define an empty model to be synced
            localStorageModel.isLoggedIn = MpcSettings.instance.isLoggedIn;
            localStorageModel.mobileEnabledCategories = [];
            localStorageModel.mobileLockedColumnIndex = null;
            localStorageModel.mobileCarouselScrollIndex = null;
            localStorageModel.usedNavTabForCarouselOrLockedColumn = null;
            localStorageModel.printPageContent = null;
            this.localStorageCache = localStorageModel;
            this.onUpdatedModel(false);
        }

        private needsValidation(): boolean {
            if (this.localStorageCache.isLoggedIn || MpcSettings.instance.isLoggedIn) return true;
            const noProducts = this.localStorageCache.products.length === 0;
            if (noProducts) return false;
            const languageWasSwitched = this.localStorageCache.pageLanguage !== MpcSettings.instance.culture;
            if (languageWasSwitched) return true;
            const expiration5MinutesAfterLastUpdate = new Date(this.localStorageCache.lastUpdateTime+(1000*60*5));
            const olderThan5Minutes = new Date().getTime() > expiration5MinutesAfterLastUpdate.getTime();
            return olderThan5Minutes;
        }

        private static isTimedOut(model: ProductComparisonModel): boolean {
            const expirationDate = new Date(model.lastUpdateTime + (1000 * 60 * 60 * 24 * 30));
            return new Date().getTime() > expirationDate.getTime();
        }

        private appendProductToList(product: Product, avoidNotification: boolean): void {
            this.localStorageCache.products.push(product);
            this.localStorageCache.hasClientSideChange = true;
            this.onUpdatedModel(false);

            if(!avoidNotification)
                this.notificationService.notifyNewProductAdded(product.title, product.mainImage, product.altTag);

            // it should only mark when adding the first article
            if (this.localStorageCache.products.length === 1) {
                this.setMobileHeaderMarkerOn();
            }
        }
        
        public checkIsProductAlreadyAdded(salesArticleVariantKey: string): boolean {
            let productExists: boolean = false;

            if (window.localStorage) {

                if (this.localStorageCache) {
                    for (let i = 0; i < this.localStorageCache.products.length; i++) {
                        const tmpProduct: Product = this.localStorageCache.products[i];

                        if (tmpProduct.articleId.salesArticleVariantKey == salesArticleVariantKey) {
                            productExists = true;
                            break;
                        }
                    }
                }
            }

            return productExists;
        }

        public storeEmptyNavigationTab(categoryName: string, navigationOrder: number): void {
            if(!this.localStorageCache.emptyNavigationTabs) {
                this.localStorageCache.emptyNavigationTabs = new Array<NavigationTab>();
            }

            let emptyNavigationTab: NavigationTab = {} as NavigationTab;
            emptyNavigationTab.name = categoryName;
            emptyNavigationTab.navigationOrder = navigationOrder;

            this.localStorageCache.emptyNavigationTabs.push(emptyNavigationTab);
            this.localStorageAbstraction.writeLocalStorage(this.localStorageCache);
        }

        public removeStoredEmptyNavigationTabs(): void {
            if(!this.localStorageCache)
                this.getModel();

            this.localStorageCache.emptyNavigationTabs = new Array<NavigationTab>();
            this.localStorageAbstraction.writeLocalStorage(this.localStorageCache);
        }

        public setClearStoredEmptyNavigationTabs(clearStoredEmptyNavigationTabs: boolean): void {
            if(!this.localStorageCache)
                this.getModel();

            this.localStorageCache.clearStoredEmptyNavigationTabs = clearStoredEmptyNavigationTabs;
            this.onUpdatedModel(true); //don't save, who cares
        }

        public getCarouselScrollIndex(): number {
            if (!this.localStorageCache) {
                this.getModel();
            }

            const currentCatName: string = this.getCurrentCategoryName();
            if (!this.localStorageCache.usedNavTabForCarouselOrLockedColumn || currentCatName != this.localStorageCache.usedNavTabForCarouselOrLockedColumn) {
                this.clearLockedColumnIndex();
                this.clearCarouselScrollIndex();
            }

            return this.localStorageCache.mobileCarouselScrollIndex;
        }

        public clearCarouselScrollIndex(): void {
            this.localStorageCache.mobileCarouselScrollIndex = null;
            this.localStorageCache.usedNavTabForCarouselOrLockedColumn = null;
            this.localStorageAbstraction.writeLocalStorage(this.localStorageCache);
        }

        public storeCarouselScrollIndex(value: number): void {
            this.localStorageCache.mobileCarouselScrollIndex = value;
            this.localStorageCache.usedNavTabForCarouselOrLockedColumn = this.getCurrentCategoryName();
            this.localStorageAbstraction.writeLocalStorage(this.localStorageCache);
        }

        public getLockedColumnIndex(): number {
            if (!this.localStorageCache) {
                this.getModel();
            }

            return this.localStorageCache.mobileLockedColumnIndex;
        }

        public clearLockedColumnIndex(): void {
            this.localStorageCache.mobileLockedColumnIndex = null;
            this.localStorageAbstraction.writeLocalStorage(this.localStorageCache);
        }

        public storeLockedColumnIndex(value: number): void {
            this.localStorageCache.mobileLockedColumnIndex = value;
            this.localStorageCache.usedNavTabForCarouselOrLockedColumn = this.getCurrentCategoryName();
            this.localStorageAbstraction.writeLocalStorage(this.localStorageCache);
        }

        private getCurrentCategoryName(): string {
            const urlParams: URLSearchParams = new URLSearchParams(location.search);
            return urlParams.get('category');
        }

        public storePrintPageContent(content: HTMLElement): void {
            this.localStorageCache.printPageContent = content.outerHTML;
            this.localStorageAbstraction.writeLocalStorage(this.localStorageCache);
        }

        public getPrintPageContentCached(): string {
            //there was a race condition with a Sync call, but we dont need to wait for server sync really
            const modelDirectlyFromLocalStorage = this.localStorageAbstraction.readLocalStorage();
            if (!modelDirectlyFromLocalStorage) return null;
            return modelDirectlyFromLocalStorage.printPageContent;
        }
    }

    export class LocalStorageAbstraction {
        private static _instance: LocalStorageAbstraction;
        public unsupported: boolean = true;
        
        private static mpcLocalStorageKeyOld: string = 'productComparison';
        public static mpcLocalStorageKeyNew: string;

        static get instance() {
            //TODO after migration period is over (i suggest 01.01.2026)
            // clean this up and just:
            // return this._instance || (this._instance = new this());
            return this._instance || LocalStorageAbstraction.getNewInstanceAndMigrate();
        }

        private static getNewInstanceAndMigrate(): LocalStorageAbstraction {
            LocalStorageAbstraction.mpcLocalStorageKeyNew = LocalStorageAbstraction.mpcLocalStorageKeyOld+"_"+MpcSettings.instance.portal;
            LocalStorageAbstraction.migrate();
            this._instance = new this();
            return this._instance;
        }

        constructor() {
            if (!window.localStorage) {
                //ErrorHandlerManager.instance.LogError(<ErrorMessage>{ message: "localStorage not supported" });
                //TODO user notification
            } else {
                this.unsupported = false;
            }
        }

        public writeLocalStorage(localStorage: ProductComparisonModel): void {
            if (this.unsupported) return;
            const tmpLocalStorageObject: string = JSON.stringify(localStorage);
            window.localStorage.setItem(LocalStorageAbstraction.mpcLocalStorageKeyNew, tmpLocalStorageObject);
        }

        public readLocalStorage(): ProductComparisonModel {
            if (this.unsupported) return null;
            const mpcLocalStorageJson = window.localStorage.getItem(LocalStorageAbstraction.mpcLocalStorageKeyNew);
            if (mpcLocalStorageJson) {
                return JSON.parse(mpcLocalStorageJson);
            }
            return null;
        }

        private static migrate(){
            if(window.localStorage.getItem(LocalStorageAbstraction.mpcLocalStorageKeyOld)){
                const oldKeyData = window.localStorage.getItem(LocalStorageAbstraction.mpcLocalStorageKeyOld);
                window.localStorage.setItem(LocalStorageAbstraction.mpcLocalStorageKeyNew, oldKeyData);
                window.localStorage.removeItem(LocalStorageAbstraction.mpcLocalStorageKeyOld);
            }
        }
    }
}