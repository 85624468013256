export interface INlVanillajaxParams {
    query?: string;
    data?: any;
    url: string;
    contentType: ContentType;
}

export enum ContentType {
    JSON,
    Plain
}

export interface HtmlResult {
    Html: string;
}

export class NlVanillajax {
    headers: any[];
    private params: INlVanillajaxParams;
    private req: XMLHttpRequest;

    constructor(params: INlVanillajaxParams) {
        this.headers = [
            { "name": "Accept", "value": "text/html,application/json" },
            { "name": "Content-Type", "value": "application/json" }
        ];
        this.params = params;
        this.params.query = params.query || "";
        this.params.data = params.data || null;
        this.params.contentType = params.contentType || ContentType.JSON;
        this.req = this.xhr(window);
    }

    post(callback: Function): NlVanillajax {
        this.open(this.req, "POST", this.params.url + (this.params.query.length ? "?" + this.params.query : ""));
        this.setHeaders(this.req, this.headers);
        this.onReadyStateChange(this.req, callback);
        this.send(this.req, this.params.data);

        return this;
    }

    postWithPromise<T>(): Promise<T> {
        let self = this;
        return new Promise(function (resolve, reject) {
            self.open(self.req, "POST", self.params.url + (self.params.query.length ? "?" + self.params.query : ""));
            self.setHeaders(self.req, self.headers);
            self.req.onload = function () {
                if (this.status >= 200 && this.status < 300) {
                    let result = self.getResult<T>(self.req);
                    if (result)
                        resolve(result);
                    else
                        reject({
                            status: this.status,
                            statusText: "response not interpretable"
                        });
                } else {
                    reject({
                        status: this.status,
                        statusText: self.req.statusText
                    });
                }
            };
            self.req.onerror = function () {
                reject({
                    status: this.status,
                    statusText: self.req.statusText
                });
            };
            self.send(self.req, self.params.data);
        });
    }

    getWithPromise(): Promise<HtmlResult> {
        let self = this;
        return new Promise(function (resolve, reject) {
            self.open(self.req, "GET", self.params.url);
            self.setHeaders(self.req, self.headers);
            //self.req.withCredentials = true;
            self.req.onload = function () {
                if (this.status >= 200 && this.status < 300) {
                    let result = self.getHtmlResult(self.req);
                    if(result)
                        resolve(result);
                    else
                        reject({
                            status: this.status,
                            statusText: "response not interpretable"
                        });
                } else {
                    reject({
                        status: this.status,
                        statusText: self.req.statusText
                    });
                }
            };
            self.req.onerror = function () {
                reject({
                    status: this.status,
                    statusText: self.req.statusText
                });
            };
            self.sendGet(self.req);
        });
    }

    private xhr(win: any): XMLHttpRequest {
        return new win.XMLHttpRequest();
    }

    private open(req: XMLHttpRequest, method: string, url: string): any {
        req.open(method, url);

        return req;
    }

    private setHeaders(req: XMLHttpRequest, headers: any[]): XMLHttpRequest {
        headers.forEach(header => req.setRequestHeader(header.name, header.value));

        return req;
    }

    private onReadyStateChange(req: XMLHttpRequest, callback: Function): XMLHttpRequest {
        req.onreadystatechange = () => {

            if (req.readyState === XMLHttpRequest.DONE) {
                let result = this.getResult(req);

                callback(result, req.status);
            }
        }

        return req;
    }

    private getHtmlResult(req: XMLHttpRequest): HtmlResult {
        let json: HtmlResult;

        if (req.responseText) {
            json = { "Html": req.responseText };
        } else {
            json = null;
        }

        return json;
    }

    private getResult<T>(req: XMLHttpRequest): T {
        let json: T;
        try {
            json = JSON.parse(req.responseText);
        } catch (e) {
            json = null;
        }

        return json;
    }

    private send(req: XMLHttpRequest, data: any): XMLHttpRequest {
        req.send(data);

        return req;
    }

    private sendGet(req: XMLHttpRequest): XMLHttpRequest {
        req.send();

        return req;
    }
}