export module MpcSettingsModule {
    export class MpcSettings {
        private static _instance: MpcSettings;
        private static initDone: boolean = false;

        static get instance() {
            return this._instance || (this._instance = new this());
        }

        public init(scope: HTMLElement): void {
            this._culture = scope.getAttribute("data-culture");
            this._portal = scope.getAttribute("data-portal");
            this._portalMandantToken = scope.getAttribute("data-portalmandanttoken");
            this._maxProducts = parseInt(scope.getAttribute("data-maxProducts"));
            this._isLoggedIn = scope.getAttribute("data-isLoggedIn") === "true";
            this._isDevelopment = scope.getAttribute("data-isDevelopment") === "true";
            this._apiUrl = scope.getAttribute("data-apiUrl");
            
            MpcSettings.initDone = true;
        }

        private _culture:string;
        get culture():string {
            if (!MpcSettings.initDone) throw new Error("MpcSettings: init not called yet");
            return this._culture;
        }

        private _portalMandantToken: string;
        get portalMandantToken(): string {
            if (!MpcSettings.initDone) throw new Error("MpcSettings: init not called yet");
            return this._portalMandantToken;
        }

        private _apiUrl: string;
        get apiUrl(): string {
            if (!MpcSettings.initDone) throw new Error("MpcSettings: init not called yet");
            return this._apiUrl;
        }
        
        private _maxProducts:number;
        get maxProducts():number {
            if (!MpcSettings.initDone) throw new Error("MpcSettings: init not called yet");
            return this._maxProducts;
        }

        private _isLoggedIn: boolean;
        get isLoggedIn(): boolean {
            if (!MpcSettings.initDone) throw new Error("MpcSettings: init not called yet");
            return this._isLoggedIn;
        }
        
        private _isDevelopment: boolean;
        get isDevelopment(): boolean {
            if (!MpcSettings.initDone) throw new Error("MpcSettings: init not called yet");
            return this._isDevelopment;
        }

        private _portal:string;
        get portal():string {
            if (!MpcSettings.initDone) throw new Error("MpcSettings: init not called yet");
            return this._portal;
        }

        public MobileCategoryNavKey: string;

    }
}