import { MPCITouchPointIdentifier} from "../typings/TouchPointIdentifier";

export module MPCTouchPointIdentifier {
    import ITouchPointIdentifier = MPCITouchPointIdentifier.ITouchPointIdentifier;
    import ServiceCallName = MPCITouchPointIdentifier.ServiceCallName;

    export class TouchPointIdentifier implements ITouchPointIdentifier {
        private static _instance: TouchPointIdentifier;
        private scope: HTMLElement;
        private compareButtonMarker: string = 'mpc-compare-button';
        private tileScaleMarker: string = 'mpc-scale-tile';

        static get instance() {
            return this._instance || (this._instance = new this());
        }

        constructor() {
            this.scope = document.body;
        }

        // For ATS and search page
        getMarkersFromDomEx(type: ServiceCallName, savKeys: string[]): HTMLElement[] {
            let scope = document.querySelector('.fas_article_tile_grid_root');

            if(!scope)
                scope = this.scope;

            let result: HTMLElement[] = [];

            savKeys.forEach((key) => {
                result.push(scope.querySelector('[data-service-call="' + type + '"][data-selected-colorid="' + key + '"]'));
            });

        return result;
        }

        getMarkersFromDom(scope?: HTMLElement, type?: ServiceCallName): NodeListOf<HTMLElement> {
            if (!scope) {
                scope = this.scope;
            }

            if (type) {
                return scope.querySelectorAll('[data-service-call="' + type + '"]');
            } else {
                return scope.querySelectorAll('[data-service-call=' + this.compareButtonMarker + '], [data-service-call=' + this.tileScaleMarker + ']');
            }
        }
        
        getTileScaleTouchPoints(scope?: HTMLElement): NodeListOf<HTMLElement> {
            if (!scope) {
                scope = this.scope;
            }

            return scope.querySelectorAll('.mpc-scope.scale-tile-container');
        }

        getCompareLinks(scope?: HTMLElement): Array<HTMLAnchorElement> {
            if (!scope) {
                scope = this.scope;
            }

            const allLinks = scope.querySelectorAll('area[href], .imagemap-inner-container>a');
            const compareLinks: Array<HTMLAnchorElement> = [];
            for (let i = 0; i < allLinks.length; ++i) {
                const link = <HTMLAnchorElement>allLinks[i];
                const href = link.href;
                if (href.indexOf('.html') >= 0  && (href.indexOf('&compare=true') >= 0 || href.indexOf('?compare=true') >= 0)) {
                    compareLinks.push(link);
                }
            }
            return compareLinks;
        }

        getMobileTouchPointPlaceholders(): NodeListOf<HTMLElement> {
            const scope = this.scope;

            return scope.querySelectorAll('.mobile-touchpoint-placeholder');
        }
    }   
}