import { MPCIFlyoutContentController } from "../typings/iFlyoutContentController";
import { MPCDeviceDetectionHelper } from "../helpers/deviceDetectionHelper";
import { MPCScrollLock } from "../helpers/scrollLock";
const SmartTimeout = require("smart-timeout");
const ArrowRightIcon = require("../../images/arrow-right.svg") as string;

export module MPCNotificationService {
    import IFlyoutContentController = MPCIFlyoutContentController.IFlyoutContentController;
    import DeviceDetectionHelper = MPCDeviceDetectionHelper.DeviceDetectionHelper;
    import ScrollLock = MPCScrollLock.ScrollLock;

    export class NotificationService {
        private readonly scope: HTMLElement;
        public flyoutContentController: IFlyoutContentController;
        private static _instance: NotificationService;
        private existingNotificationTimeout: any;
        private deviceDetectionHelper : DeviceDetectionHelper;
        private flyoutContainer: HTMLElement;
        private scrollLock: ScrollLock;

        private maxProductsCountNotification: HTMLElement;
        private newProductNotification: HTMLElement;
        private emptyListNotification: HTMLElement;
        private flyoutIconContainer: HTMLElement;

        static get instance() {
            return this._instance || (this._instance = new this());
        }

        constructor() {
            this.scope = document.querySelector('.pcf-scope');

            if(this.scope)
            {
                this.init();
            }
        }
        
        private init(): void {
            this.deviceDetectionHelper = DeviceDetectionHelper.instance;
            this.scrollLock = ScrollLock.instance;
            this.flyoutContainer = this.scope.querySelector('.flyout-container');
            const notifications = this.flyoutContainer.querySelectorAll(".notification");

            for (let notification of <HTMLElement[]><any>notifications) {
                const closeBtns = notification.querySelectorAll('.close-flyout-btn'); //multiple because there is the x for Web and the footer button for Mobile
                const openFlyoutBtns = notification.querySelectorAll('.open-flyout-button');//multiple because "new-product' has two (Web has a compact layout without footer)
                const navigationBtn = notification.querySelector('.navigate-to-products-button');

                for (let comparisonListButton of <HTMLElement[]><any>openFlyoutBtns) {
                    const iconSlot: HTMLElement = comparisonListButton.querySelector('.open-flyout-button-icon');
                    iconSlot.innerHTML = ArrowRightIcon;
                    comparisonListButton.addEventListener('click', ()=> { this.goToFlyoutComparisonList(notification); });
                }

                for (let closeBtn of <HTMLElement[]><any>closeBtns) {
                    closeBtn.addEventListener('click', () => { this.closeNotification(notification); });
                }

                if (navigationBtn != null) {
                    const iconSlot: HTMLElement = navigationBtn.querySelector('.navigate-products-button-icon');
                    iconSlot.innerHTML = ArrowRightIcon;
                }
            }
            
            this.maxProductsCountNotification = this.scope.querySelector('.max-products-count-notification');
            this.newProductNotification = this.scope.querySelector('.new-product-notification');
            this.emptyListNotification = this.scope.querySelector('.empty-list-notification');
            this.flyoutIconContainer = this.scope.querySelector('.flyout-icon-container');

            // close any active notification flyout if any other flyout is opening from headR:
            // we need it because notification flyouts are not fragments and headR is not responsible for them
            window.shell.subscribeTo('ESMKT.HeadR.FlyoutOpening', () => { this.closeAnyActiveNotification(); }, 'ESMKT.HeadR.FlyoutOpening');
        }

        notifyNewProductAdded(title: string, mainImage: string, altTag: string): void {
            this.showNotification(this.newProductNotification, () => {
                this.replaceNotificationContent(title, mainImage, altTag);
            });
        }
        
        notifyMaximumProductsCountReached(): void {
            this.showNotification(this.maxProductsCountNotification, null);
        }

        notifyEmptyList(): void {
            this.initializeNavigationButtonOnEmptyNotificationOnce(); //Workaround because this class exists twice (PCF & PCP)
            this.showNotification(this.emptyListNotification, null);
        }

        closeAnyActiveNotification(): void {
            if (this.maxProductsCountNotification.classList.contains('show-notification'))
                this.closeNotification(this.maxProductsCountNotification);

            if (this.newProductNotification.classList.contains('show-notification'))
                this.closeNotification(this.newProductNotification);
            
            if (this.emptyListNotification.classList.contains('show-notification'))
                this.closeNotification(this.emptyListNotification);
        }

        private initializeNavigationButtonOnEmptyNotificationOnce(): void {
            const navigationBtn = this.emptyListNotification.querySelector('.navigate-to-products-button');

            const eventFunction: EventListener = () => {
                this.showMobileHeaderMenu();
                this.closeNotification(this.emptyListNotification);
                navigationBtn.removeEventListener('click', eventFunction);
            }

            if (navigationBtn != null && window.headrapi)
                navigationBtn.addEventListener('click', eventFunction);
        }

        private showMobileHeaderMenu(): void {
            if (window.headrapi)
                window.headrapi.toggleMenu();
        }

        private replaceNotificationContent(title: string, mainImage: string, altTag: string): void {
            const productInfoContainer: HTMLElement
                = this.newProductNotification.querySelector('.product-info-container');

            productInfoContainer.querySelector('.description-container')
                .querySelector('.title').innerHTML = title;
            productInfoContainer.querySelector('.image-slot').setAttribute('src', mainImage);
            productInfoContainer.querySelector('.image-slot').setAttribute('alt', altTag);
        }

        private goToFlyoutComparisonList(notification: HTMLElement): void {
            this.closeNotification(notification);

            window.shell.publishTo('ESMKT.HeadR.ToggleFlyout', 'ManualProductComparison');
        }

        private closeExistingNotification(): void {
            const existingNotification: HTMLElement = this.flyoutContainer.querySelector('.show-notification');

            if (existingNotification) {
                SmartTimeout.clear(this.closeExistingNotification);
                existingNotification.classList.remove('show-notification');
                this.renderFlyoutContainerOverlay(false);
            }
        }

        private closeNotification(notification: HTMLElement): void {
            SmartTimeout.clear(this.closeExistingNotification);
            notification.classList.remove('show-notification');
            this.flyoutIconContainer.classList.remove('notification_background');
            this.renderFlyoutContainerOverlay(false);
        }

        private showNotification(notification: HTMLElement, updateNotificationContent?: () => void): void {
            this.triggerAppShellPublish();

            // on category page, you can click many compare buttons fast, so check for older notification
            let deferNotification = false;
            if (this.existingNotificationTimeout ||
                notification.classList.contains('show-notification')) {
                this.closeExistingNotification();
                deferNotification = true;
            } 

            let showInner = () => {
                if (this.flyoutContentController)
                    this.flyoutContentController.hideFlyoutContent();

                if (updateNotificationContent)
                    updateNotificationContent();

                this.renderFlyoutContainerOverlay(true);

                notification.classList.add('show-notification');
                this.flyoutIconContainer.classList.add('notification_background');

                if (!this.deviceDetectionHelper.isMobileShop()) {
                    // close new product notification after 4500 ms
                    this.existingNotificationTimeout =
                        SmartTimeout.set(() => { this.closeNotification(notification) }, 4500);
                }
            };

            if(deferNotification)
                SmartTimeout.set(showInner, 500);
            else 
                showInner();
        }

        private triggerAppShellPublish(): void {
            // close modal dialogs in legacy WF
            if (window.shell) {
                window.shell.publishTo('mpc.close-modal', '');
                window.shell.publishTo('ESPP.OrderModal.Close', '');
            }
        }

        private renderFlyoutContainerOverlay(renderOverlay: boolean): void {
            if(this.deviceDetectionHelper.isMobileShop()) {
                if(renderOverlay) {
                    this.scope.classList.add('render-overlay');
                    this.scope.ontouchmove = (event: TouchEvent) => { event.preventDefault(); }; // iOS ScrollLock
                    this.scrollLock.lock();
                }
                else {
                    if(this.scope.classList.contains('render-overlay')) {
                        this.scope.classList.remove('render-overlay');
                        this.scope.ontouchmove = () => { };
                        this.scrollLock.unlock();
                    }
                }
            }
        }

    }
}