import {MPCElementsHelper} from "../helpers/htmlElementsHelper";
import {MPCINavigationService} from "../typings/iNavigationService";
import { MPCIAbstractContent } from "../typings/iAbstractContent";
import { MPCNotificationService } from "../PCF/notification-service";
import { NavigationTab } from "../../../Models/Product.generated";
import { MPCDeviceDetectionHelper } from "../helpers/deviceDetectionHelper";
import { MPCLocalStorageService } from "../local-storage-service";

export module MPCNavigationService {
    import INavigationService = MPCINavigationService.INavigationService;
    import HtmlElementsHelper = MPCElementsHelper.HtmlElementsHelper;
    import IAbstractContent = MPCIAbstractContent.IAbstractContent;
    import NotificationService = MPCNotificationService.NotificationService;
    import DeviceDetectionHelper = MPCDeviceDetectionHelper.DeviceDetectionHelper;
    import ProductComparisonModelManager = MPCLocalStorageService.ProductComparisonModelManager;

    //extend the service interface by some properties that are only used in TS
    interface NavigationTabTs extends NavigationTab {
        domNode: HTMLElement;
        savKeys: string[];
    }

    export class NavigationService implements INavigationService {
        private static _instance: NavigationService;
        private navigationTabs: HTMLElement;
        private selectCategoryButton: HTMLElement;
        private navigationTabsArray: Array<NavigationTabTs>;
        private activeNavigationTab: NavigationTabTs;
        private parentElement: HTMLElement;
        private navigationTabKeyName: string;
        private abstractContent: IAbstractContent;
        private notificationService: NotificationService;
        private deviceDetectionHelper: DeviceDetectionHelper;
        private productComparisonModelManager: ProductComparisonModelManager;

        static get instance() {
            return this._instance || (this._instance = new this());
        }

        init(parentElement: HTMLElement, navigationTabKeyName: string, abstractContent: IAbstractContent): void {

            if (parentElement) {
                this.deviceDetectionHelper = DeviceDetectionHelper.instance;
                this.notificationService = NotificationService.instance;
                this.parentElement = parentElement;
                this.selectCategoryButton = this.parentElement.querySelector('.select-category-button');
                this.navigationTabs = this.parentElement.querySelector('.navigation-tabs');
                this.navigationTabsArray = new Array<NavigationTabTs>();
                this.navigationTabKeyName = navigationTabKeyName;
                this.abstractContent = abstractContent;
                this.registerButtonEvent();
            }
        }

        private registerButtonEvent(): void {
            if (this.selectCategoryButton)
                this.selectCategoryButton.addEventListener('click', () => {
                    this.notificationService.notifyEmptyList();
                });
        }

        private switchNavigationTab(newActiveNavigationTab: NavigationTabTs): void {
            if (this.activeNavigationTab) {
                this.activeNavigationTab.domNode.classList.remove('active-tab');

                const newActiveNavigationTabName: string = newActiveNavigationTab.name;
                const oldNavigationTabName: string = this.activeNavigationTab.name;

                if (this.deviceDetectionHelper.isMobileShop()) {
                    // clear locked column info:
                    this.productComparisonModelManager = ProductComparisonModelManager.instance;
                    this.productComparisonModelManager.clearLockedColumnIndex();
                    this.productComparisonModelManager.clearCarouselScrollIndex();
                }

                if (this.abstractContent) {
                    this.abstractContent.updateContainerListVisibility(newActiveNavigationTabName, oldNavigationTabName);
                }
            }

            this.activeNavigationTab = newActiveNavigationTab;
            newActiveNavigationTab.domNode.classList.add('active-tab');
        }

        private scrollToNavigationTab(newActiveNavigationTab: NavigationTabTs): void {
            const buttonLeftEdgePosition = newActiveNavigationTab.domNode.getBoundingClientRect().left;
            const buttonRightEdgePosition = buttonLeftEdgePosition + newActiveNavigationTab.domNode.offsetWidth;
            const rightMargin = 10;

            if (buttonRightEdgePosition > window.innerWidth) {
                this.navigationTabs.scrollLeft = buttonRightEdgePosition + rightMargin - window.innerWidth;
            }
        }

        public createOrUpdateNavigationTab(categoryName: string, counterNumber: number, navigationOrder: number,
            salesArticleVariantKey: string, createEmptyNavTab: boolean = false): void {
            let isNew: boolean = true;
            
            // look for existing tab:
            for(const navigationTab of this.navigationTabsArray) {
                const currentNavigationTabName = navigationTab.domNode.getAttribute(this.navigationTabKeyName);
                if(currentNavigationTabName === categoryName) {
                    if (navigationTab.savKeys.indexOf(salesArticleVariantKey) >= 0) {
                        return; //sav already added (happens when ajax reloads)
                    }
                    if (counterNumber > 0) {
                        navigationTab.savKeys.push(salesArticleVariantKey);
                        if(!this.abstractContent)//that URL is only used from PCP
                            this.appendSavKeyToNavTabUrl(navigationTab, salesArticleVariantKey);
                    } else if (counterNumber < 0) {
                        navigationTab.savKeys = navigationTab.savKeys.slice(navigationTab.savKeys.indexOf(salesArticleVariantKey), 1);
                        //no removal from url nessesary because that URL is only used from PCP, where articles are only added 
                        //(when loading the page, remove triggers a page reload)
                    }
                    this.updateCounter(navigationTab, counterNumber);

                    isNew = false;
                    break;
                }
            }

            if (isNew) {
                const newNavigationTab: NavigationTabTs = this.createNavigationTab(categoryName, salesArticleVariantKey,
                    navigationOrder, createEmptyNavTab);
                this.updateCounter(newNavigationTab, counterNumber);
                let indexToInsert = 0;
                for (let navigationTab of this.navigationTabsArray) {
                    if (navigationOrder < navigationTab.navigationOrder) {
                        break;
                    }
                    indexToInsert++;
                }

                this.navigationTabsArray.splice(indexToInsert, 0, newNavigationTab);
                
                // tab navigation:
                newNavigationTab.domNode.setAttribute('focusable', '');

                HtmlElementsHelper.appendChildAtIndex(newNavigationTab.domNode, indexToInsert, this.navigationTabs);
                newNavigationTab.domNode.addEventListener('click', () => {
                    this.switchNavigationTab(newNavigationTab);
                });

                if (window.shell.tabNav !== undefined)
                    window.shell.tabNav.onActivated('.navigation-tab .has-focus', 
                        () => {  this.switchNavigationTab(newNavigationTab); });
            }
        }

        public updateSelectCategoryButtonVisibility(): void {
            if (!this.selectCategoryButton)
                return;

            if (!this.isAnyProductCounted())
                this.selectCategoryButton.classList.remove("hidden");
        }

        private isAnyProductCounted(): boolean {
            let productFound = false;
            this.navigationTabsArray.forEach((tab: NavigationTabTs) => {
                if (this.getCounterNumber(tab)) {
                    productFound = true;
                    return;
                }
            });

            return productFound;
        }

        private updateCounter(navigationTab: NavigationTabTs, counterNumber: number): void {
            const productsCounter: HTMLElement = navigationTab.domNode.querySelector('.products-counter');
            let counter: number = Number(productsCounter.textContent.trim());
            
            counter += counterNumber;
            productsCounter.innerText = counter.toString();
        }

        private getCounterNumber(navigationTab: NavigationTabTs): number {
            const productsCounter: HTMLElement = navigationTab.domNode.querySelector('.products-counter');
            return Number(productsCounter.innerText);
        }
        
        private appendSavKeyToNavTabUrl(navigationTab: NavigationTabTs, salesArticleVariantKey: string): void {
            let comparisonPageUri: string = navigationTab.domNode.getAttribute('href');
            comparisonPageUri = comparisonPageUri + '&savKeys=' + salesArticleVariantKey;
            navigationTab.domNode.setAttribute('href', comparisonPageUri);
        }

        private createNavigationTab(categoryName: string, salesArticleVariantKey: string, order: number,
            createEmptyNavTab: boolean): NavigationTabTs {
            let newNavigationTab: NavigationTabTs = {} as NavigationTabTs;
            newNavigationTab.navigationOrder = order;
            newNavigationTab.name = categoryName;
            newNavigationTab.savKeys = [salesArticleVariantKey];
            let newNavigationTabHtml: HTMLElement;

            if (salesArticleVariantKey || createEmptyNavTab) {
                // create navigation ab from comparison page:
                newNavigationTabHtml = document.createElement('a');

                // comparisonPageUri is used in PCP page, flyout does not need it:
                let comparisonPageUri: string;
                if (!this.abstractContent) {
                    comparisonPageUri = '?category=' + encodeURIComponent(categoryName);
                    if (salesArticleVariantKey) {
                        comparisonPageUri += '&savKeys=' + salesArticleVariantKey;
                    }
                } else {
                    comparisonPageUri = 'javascript:';
                }

                newNavigationTabHtml.setAttribute('href', comparisonPageUri);
            }
            else {
                // create navigation tab for flyout:
                newNavigationTabHtml = document.createElement('div');
            }
            
            const newCategoryName: HTMLElement = document.createElement('div');
            const newArticlesCounter: HTMLElement = document.createElement('div');

            newNavigationTabHtml.className = 'navigation-tab';
            newNavigationTabHtml.setAttribute(this.navigationTabKeyName, categoryName);
            newCategoryName.className = 'category-name';
            newCategoryName.innerText = categoryName;
            newArticlesCounter.className = 'products-counter';
            
            newNavigationTabHtml.appendChild(newCategoryName);
            newNavigationTabHtml.appendChild(newArticlesCounter);

            newNavigationTabHtml.addEventListener('keydown', (event: any) => {
                // prevent page jump down on Space btn., click
                if (event.code === 'Space')
                    event.preventDefault();

                if(!window.shell.tabNav)
                    return;
        
                if (event.code === 'ArrowRight' && event.target.nextElementSibling) {
                    window.shell.tabNav.focus(event.target.nextElementSibling);
                }
        
                if (event.code === 'ArrowLeft' && event.target.previousElementSibling) {
                    window.shell.tabNav.focus(event.target.previousElementSibling);
                }
            });

            newNavigationTab.domNode = newNavigationTabHtml;
            return newNavigationTab;
        }

        public removeEmptyNavigationTabs(): void {
            if(this.navigationTabsArray) {
                let removableNavigationTabs: Array<NavigationTabTs> = new Array<NavigationTabTs>();

                for (const navigationTab of this.navigationTabsArray) {
                    const productsCounter: HTMLElement = navigationTab.domNode.querySelector('.products-counter');
                    const counter: number = Number(productsCounter.innerText);

                    if (counter == 0) {
                        removableNavigationTabs.push(navigationTab);
                        
                        // remove highlight from last navigation tab:
                        // temporary disabled by customer: ESPP-1523
                        /* if (this.navigationTabsArray.length == 1) {
                            this.navigationTabsArray[0].classList.remove('active-tab');
                        } */
                    }
                }

                for (const tmpTab of removableNavigationTabs) {
                    this.navigationTabs.removeChild(tmpTab.domNode);
                    this.navigationTabsArray.splice(this.navigationTabsArray.indexOf(tmpTab), 1);
                }
            }
        }

        public markActiveNavigationTab(categoryName: string): boolean {
            let newTabActivated: boolean;
            for (const navigationTab of this.navigationTabsArray) {
                let navigationTabName: string = navigationTab.domNode.getAttribute(this.navigationTabKeyName);

                if (navigationTabName === categoryName) {
                    this.switchNavigationTab(navigationTab);
                    this.scrollToNavigationTab(navigationTab);
                    newTabActivated = true;
                    break;
                }
            }

            return newTabActivated;
        }

        public markAnyNavigationTabActive(): string {
            // after category was cleared, active tab is not selected.
            // Need to define last one o first one navigation tab from navigation service container.
            let newActiveTabCatName: string;

            if (this.navigationTabsArray && this.navigationTabsArray.length > 0) {
                const newActiveTab: NavigationTabTs =
                    this.navigationTabsArray[this.navigationTabsArray.length - 1];

                newActiveTabCatName = newActiveTab.name;
                this.switchNavigationTab(newActiveTab);
                this.scrollToNavigationTab(newActiveTab);
            }

            return newActiveTabCatName;
        }

        public getFirstNavigationTab(): HTMLElement {
            if (this.navigationTabsArray && this.navigationTabsArray.length > 0) {
                const firstTab: NavigationTabTs = this.navigationTabsArray[0];

                return firstTab.domNode;
            }

            return;
        }
    }
}